<template>
  <b-card class="dashboard__tab-content-card">
    <b-row class="match-height">
      <b-col :md="(fileInfo && ['Niche', 'Little Buddha', 'Pedestal'].includes(fileInfo.nvsFileType)) ? 4 : 6">
        <b-card
          class="has-padding"
        >
          <b-card-title>
            File Details
          </b-card-title>
          <b-card-body>
            <b-table-simple
              borderless
              responsive
              class="particulars_of_deceased d-none d-md-block"
            >
              <b-tbody>
                <b-tr>
                  <b-th width="230px">
                    File No.
                  </b-th>
                  <b-td>{{ fileInfo.fileNo ? fileInfo.fileNo : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Booking Date
                  </b-th>
                  <b-td>{{ fileInfo.bookingDate ? dateFormat(fileInfo.bookingDate) : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Confirm Date
                  </b-th>
                  <b-td>{{ fileInfo.confirmDate ? dateFormat(fileInfo.confirmDate) : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Confirmed By
                  </b-th>
                  <b-td>{{ fileInfo.confirmedBy ? fileInfo.confirmedBy : '-' }}</b-td>
                </b-tr>
                <b-tr v-if="['Niche'].includes(fileInfo.nvsFileType)">
                  <b-th width="230px">
                    Exercise Date
                  </b-th>
                  <b-td>{{ fileInfo.exerciseDate ? dateFormat(fileInfo.exerciseDate) : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Product Description
                  </b-th>
                  <b-td>{{ fileInfo.nvsFileType ? fileInfo.nvsFileType : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Payment Method
                  </b-th>
                  <b-td>{{ (fileInfo.ledgerSummary && fileInfo.ledgerSummary.paymentMethod) ? fileInfo.ledgerSummary.paymentMethod : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Customer Type
                  </b-th>
                  <b-td class="text-capitalize">
                    {{ fileInfo.customerType ? fileInfo.customerType.toLowerCase() : '-' }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Need Type
                  </b-th>
                  <b-td>{{ fileInfo.needType ? fileInfo.needType : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Bury At NV
                  </b-th>
                  <b-td>{{ fileInfo.buryAtNV ? fileInfo.buryAtNV : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Purchase Order No.
                  </b-th>
                  <b-td>{{ fileInfo.poNo ? fileInfo.poNo : '-' }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <hr>

            <div>
              <h5 class="font-weight-bolder">
                Remarks
              </h5>
              <b-row>
                <b-col
                  class="content-header-left"
                  cols="9"
                  md="9"
                  sm="9"
                  lg="10"
                >
                  <p>{{ fileInfo.remarks ? fileInfo.remarks : '' }}</p>
                </b-col>
                <b-col
                  class="content-header-left text-right"
                  cols="3"
                  md="3"
                  sm="3"
                  lg="2"
                >
                  <b-button
                    v-if="canViewThisAction('update', 'File')"
                    v-b-tooltip.hover.top="'Edit Remarks'"
                    variant="flat-primary"
                    class="edit_remark_button"
                    @click="editRemarks"
                  >
                    <feather-icon
                      class="cursor-pointer"
                      icon="EditIcon"
                      size="16"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col :md="(fileInfo && ['Niche', 'Little Buddha', 'Pedestal'].includes(fileInfo.nvsFileType)) ? 4 : 6">
        <b-card
          class="has-padding"
        >
          <b-card-title>
            Purchaser Details
          </b-card-title>
          <b-card-body>
            <b-tabs
              v-if="fileInfo.purchasers && fileInfo.purchasers.length"
              class="purchaser-tabs"
              content-class="mt-2"
            >
              <b-tab
                v-for="(purchaser, key) in fileInfo.purchasers"
                :key="'purchaser' + key"
                :title="'PCR #' + (key + 1)"
              >
                <b-card-text>
                  <b-table-simple
                    borderless
                    responsive
                    class="particulars_of_deceased d-none d-md-block"
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Purchaser Name
                        </b-th>
                        <b-td>
                          <b-button
                            v-if="canViewThisAction('show', 'Customer') && purchaser.customer"
                            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                            variant="link"
                            class="weight-600 align-middle detail-view-id text-uppercase"
                            :to="{ name: 'customers-customers-show', params: { id: purchaser.customer._id } }"
                          >
                            {{ purchaser.name }}
                          </b-button>
                          <b-button
                            v-else
                            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                            variant="none"
                            class="text-bold-black align-middle not-button text-uppercase"
                          >
                            {{ purchaser.name }}
                          </b-button>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          NRIC
                        </b-th>
                        <b-td>{{ purchaser.nric ? purchaser.nric : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Date of Birth
                        </b-th>
                        <b-td>{{ (purchaser.customer && purchaser.customer.dob) ? dateFormat(purchaser.customer.dob) : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Sex
                        </b-th>
                        <b-td>{{ (purchaser.customer && purchaser.customer.sex) ? purchaser.customer.sex : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Race
                        </b-th>
                        <b-td>{{ (purchaser.customer && purchaser.customer.race) ? purchaser.customer.race : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Nationality
                        </b-th>
                        <b-td>{{ (purchaser.customer && purchaser.customer.nationality) ? purchaser.customer.nationality : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Mobile No.
                        </b-th>
                        <b-td>{{ purchaser.contact ? purchaser.contact : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Email Address
                        </b-th>
                        <b-td class="">
                          {{ purchaser.email ? purchaser.email : '-' }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Residential Address
                        </b-th>
                        <b-td>
                          {{ getResidentialAddress(purchaser) }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Nirvana SG User
                        </b-th>
                        <b-td>{{ (purchaser.customer && purchaser.customer.password) ? 'Yes' : 'No' }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        v-if="(fileInfo && ['Niche', 'Little Buddha', 'Pedestal'].includes(fileInfo.nvsFileType))"
        :md="(fileInfo && ['Niche', 'Little Buddha', 'Pedestal'].includes(fileInfo.nvsFileType)) ? 4 : 6"
      >
        <b-card
          class="has-padding"
        >
          <b-card-title>
            Lot Details
          </b-card-title>
          <b-card-body>
            <b-table-simple
              borderless
              responsive
              class="particulars_of_deceased d-none d-md-block"
            >
              <b-tbody>
                <b-tr>
                  <b-th width="230px">
                    Zone No.
                  </b-th>
                  <b-td>{{ relatedLot.zoneNo || '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Lot No.
                  </b-th>
                  <b-td>{{ relatedLot.lotNo || '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Lot Type
                  </b-th>
                  <b-td>{{ relatedLot.lotType || '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Bury Capacity
                  </b-th>
                  <b-td>{{ relatedLot.buryCapacity || '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Area Length
                  </b-th>
                  <b-td>{{ relatedLot.areaLength || '-' }}</b-td>
                </b-tr>
                <b-tr v-if="!['Little Buddha', 'Pedestal'].includes(fileInfo.nvsFileType)">
                  <b-th width="230px">
                    Direction
                  </b-th>
                  <b-td>{{ relatedLot.direction || '-' }}</b-td>
                </b-tr>
                <b-tr v-if="!['Little Buddha', 'Pedestal'].includes(fileInfo.nvsFileType)">
                  <b-th width="230px">
                    Side
                  </b-th>
                  <b-td>{{ relatedLot.side || '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Level No.
                  </b-th>
                  <b-td>{{ relatedLot.levelNo || '-' }}</b-td>
                </b-tr>
                <b-tr v-if="!['Little Buddha', 'Pedestal'].includes(fileInfo.nvsFileType)">
                  <b-th width="230px">
                    Section No.
                  </b-th>
                  <b-td>{{ relatedLot.section || '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Land Category
                  </b-th>
                  <b-td>{{ relatedLot.lotCategory || '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Interment Date
                  </b-th>
                  <b-td>{{ relatedLot.intermentDate ? dateFormat(relatedLot.intermentDate) : '-' }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <hr>

            <div>
              <h5 class="font-weight-bolder">
                Remarks
              </h5>
              <b-row>
                <b-col
                  class="content-header-left"
                  cols="12"
                  md="12"
                >
                  <p>{{ relatedLot.remarks || '-' }}</p>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-if="fileInfo && fileInfo.nvsFileType != 'Little Buddha'"
      class="match-height"
    >
      <b-col
        v-if="fileInfo.nvsFileType != 'Urn'"
        md="4"
      >
        <b-card
          class="has-padding"
        >
          <b-card-title>
            Certificate Details
          </b-card-title>
          <b-card-body>
            <b-table-simple
              borderless
              responsive
              class="particulars_of_deceased d-none d-md-block"
            >
              <b-tbody>
                <b-tr>
                  <b-th width="230px">
                    Cert No
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.certificateNo ? fileInfo.certificate.certificateNo : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Batch No.
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.batchNo ? fileInfo.certificate.batchNo : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Cert Date
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.certificateDate ? dateFormat(fileInfo.certificate.certificateDate) : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Send Date
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.sendDate ? dateFormat(fileInfo.certificate.sendDate) : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Receive Date
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.receiveDate ? dateFormat(fileInfo.certificate.receiveDate) : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Issue Date
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.issueDate ? dateFormatWithFormatParam(fileInfo.certificate.issueDate, 'DD/MM/YYYY, hh:mm:ssa') : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Delivery Method
                  </b-th>
                  <b-td>
                    <span>
                      {{ fileInfo.certificate ? (fileInfo.certificate.deliveryMethod ? fileInfo.certificate.deliveryMethod : '-') : '-' }}
                    </span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Delivery Status
                  </b-th>
                  <b-td>
                    <span class="text-success weight-500">
                      {{ fileInfo.certificate ? (fileInfo.certificate.deliveryStatus ? fileInfo.certificate.deliveryStatus : '-') : '-' }}
                    </span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Cert Transfer Date
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.certificateTransferDate ? dateFormat(fileInfo.certificate.certificateTransferDate) : '-') : '-' }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th width="230px">
                    Cert Duplicate Date
                  </b-th>
                  <b-td>{{ fileInfo.certificate ? (fileInfo.certificate.certificateDuplicateDate ? dateFormat(fileInfo.certificate.certificateDuplicateDate) : '-') : '-' }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <hr>

            <div>
              <h5 class="font-weight-bolder">
                Remarks
              </h5>
              <b-row>
                <b-col
                  class="content-header-left"
                  cols="12"
                  md="12"
                >
                  <p>{{ fileInfo.certificate ? (fileInfo.certificate.remarks ? fileInfo.certificate.remarks : '-') : '-' }}</p>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col :md="fileInfo.nvsFileType == 'Urn' ? 12 : 8">
        <b-card
          class="has-padding"
        >
          <b-card-title>
            Related Nominees
          </b-card-title>
          <b-card-body>
            <b-tabs
              class="purchaser-tabs"
              content-class="mt-2"
            >
              <b-tab
                v-if="!['Urn', 'Pedestal', 'Little Buddha'].includes(fileInfo.nvsFileType)"
                :title="'Intended User ' + (fileInfo.intendedUsers ? (`(${fileInfo.intendedUsers.length})`) : '(0)')"
              >
                <b-card-text>
                  <b-row
                    v-if="fileInfo && fileInfo.intendedUsers && fileInfo.intendedUsers.length"
                    class="match-height"
                  >
                    <b-col
                      v-for="(iu, key) in fileInfo.intendedUsers.slice(0, 2)"
                      :key="'iu' + key"
                      md="6"
                    >
                      <b-card
                        class="card-transaction iu-card"
                        no-body
                      >
                        <b-card-header class="no-bottom-border">
                          <b-card-title>Intended User #{{ key + 1 }}</b-card-title>
                          <b-button
                            variant="none"
                            class="btn-icon rounded-circle ml-auto mr-1 flat-primary-bg"
                          >
                            <feather-icon icon="UserIcon" />
                          </b-button>
                          <b-dropdown
                            variant="link"
                            no-caret
                            class="chart-dropdown"
                            toggle-class="p-0"
                            right
                          >
                            <template #button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="18"
                                class="text-body cursor-pointer"
                              />
                            </template>
                            <b-dropdown-item
                              v-if="canViewThisAction('nominee-edit', 'File')"
                              @click="editUser(iu, key)"
                            >
                              Edit
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-if="canViewThisAction('nominee-delete', 'File')"
                              @click="removeUser(iu, key)"
                            >
                              Remove
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-card-header>
                        <b-card-body>
                          <b-button
                            v-if="canViewThisAction('show', 'Customer') && iu.customer"
                            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                            variant="link"
                            class="weight-600 align-middle detail-view-id text-uppercase"
                            :to="{ name: 'customers-customers-show', params: { id: iu.customer._id } }"
                          >
                            {{ iu.name }}
                          </b-button>
                          <b-button
                            v-else
                            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                            variant="none"
                            class="text-bold-black align-middle not-button text-uppercase"
                          >
                            {{ iu.name }}
                          </b-button>
                          <b-badge
                            v-if="iu.isPurchaser"
                            variant="light-info"
                            class="ml-50"
                          >
                            {{ iu.purchaserNo }}
                          </b-badge>
                          <br>
                          <span>{{ iu.nric }}</span>
                          <br>
                          <span>{{ iu.relation || '-' }}</span>
                          <br>
                          <span>
                            <feather-icon
                              icon="TabletIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            {{ iu.contact || '-' }}
                          </span>
                          <br>
                          <span>
                            <feather-icon
                              icon="CalendarIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            {{ (iu.customer && iu.customer.dob) ? dateFormat(iu.customer.dob) : '-' }}
                          </span>
                          <br>
                          <span>
                            <feather-icon
                              icon="MapPinIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            <span>
                              {{ getResidentialAddress(iu) }}
                            </span>
                          </span>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                  <div
                    v-else
                    class="empty_block"
                  >
                    <h4>No Data Yet</h4>
                    <span class="align-middle">Item will show once the data is entered</span>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab :title="'Next of Kin ' + (fileInfo.nextOfKins ? (`(${fileInfo.nextOfKins.length})`) : '(0)')">
                <b-card-text>
                  <b-row
                    v-if="fileInfo && fileInfo.nextOfKins && fileInfo.nextOfKins.length"
                    class="match-height"
                  >
                    <b-col
                      v-for="(iu, key) in fileInfo.nextOfKins.slice(0, 2)"
                      :key="'iu' + key"
                      md="6"
                    >
                      <b-card
                        class="card-transaction iu-card"
                        no-body
                      >
                        <b-card-header class="no-bottom-border">
                          <b-card-title>Next of Kin #{{ key + 1 }}</b-card-title>
                          <b-button
                            variant="none"
                            class="btn-icon rounded-circle ml-auto mr-1 flat-primary-bg"
                          >
                            <feather-icon icon="UserIcon" />
                          </b-button>
                          <b-dropdown
                            variant="link"
                            no-caret
                            class="chart-dropdown"
                            toggle-class="p-0"
                            right
                          >
                            <template #button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="18"
                                class="text-body cursor-pointer"
                              />
                            </template>
                            <b-dropdown-item
                              v-if="canViewThisAction('nominee-edit', 'File')"
                              @click="editUser(iu, key)"
                            >
                              Edit
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-if="canViewThisAction('nominee-delete', 'File')"
                              @click="removeUser(iu, key)"
                            >
                              Remove
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-card-header>
                        <b-card-body>
                          <b-button
                            v-if="canViewThisAction('show', 'Customer') && iu.customer"
                            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                            variant="link"
                            class="weight-600 align-middle detail-view-id text-uppercase"
                            :to="{ name: 'customers-customers-show', params: { id: iu.customer._id } }"
                          >
                            {{ iu.name }}
                          </b-button>
                          <b-button
                            v-else
                            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                            variant="none"
                            class="text-bold-black align-middle not-button text-uppercase"
                          >
                            {{ iu.name }}
                          </b-button>
                          <b-badge
                            v-if="iu.isPurchaser"
                            variant="light-info"
                            class="ml-50"
                          >
                            {{ iu.purchaserNo }}
                          </b-badge>
                          <br>
                          <span>{{ iu.nric }}</span>
                          <br>
                          <span>{{ iu.relation || '-' }}</span>
                          <br>
                          <span>
                            <feather-icon
                              icon="TabletIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            {{ iu.contact || '-' }}
                          </span>
                          <br>
                          <span>
                            <feather-icon
                              icon="CalendarIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            {{ (iu.customer && iu.customer.dob) ? dateFormat(iu.customer.dob) : '-' }}
                          </span>
                          <br>
                          <span>
                            <feather-icon
                              icon="MapPinIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            <span>
                              {{ getResidentialAddress(iu) }}
                            </span>
                          </span>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                  <div
                    v-else
                    class="empty_block"
                  >
                    <h4>No Data Yet</h4>
                    <span class="align-middle">Item will show once the data is entered</span>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab :title="'Addressee ' + (fileInfo.addressees ? (`(${fileInfo.addressees.length})`) : '(0)')">
                <b-card-text>
                  <b-row
                    v-if="fileInfo && fileInfo.addressees && fileInfo.addressees.length"
                    class="match-height"
                  >
                    <b-col
                      v-for="(iu, key) in fileInfo.addressees.slice(0, 2)"
                      :key="'iu' + key"
                      md="6"
                    >
                      <b-card
                        class="card-transaction iu-card"
                        no-body
                      >
                        <b-card-header class="no-bottom-border">
                          <b-card-title>Addressee #{{ key + 1 }}</b-card-title>
                          <b-button
                            variant="none"
                            class="btn-icon rounded-circle ml-auto mr-1 flat-primary-bg"
                          >
                            <feather-icon icon="UserIcon" />
                          </b-button>
                          <b-dropdown
                            variant="link"
                            no-caret
                            class="chart-dropdown"
                            toggle-class="p-0"
                            right
                          >
                            <template #button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="18"
                                class="text-body cursor-pointer"
                              />
                            </template>
                            <b-dropdown-item
                              v-if="canViewThisAction('nominee-edit', 'File')"
                              @click="editUser(iu, key)"
                            >
                              Edit
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-if="canViewThisAction('nominee-delete', 'File')"
                              @click="removeUser(iu, key)"
                            >
                              Remove
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-card-header>
                        <b-card-body>
                          <b-button
                            v-if="canViewThisAction('show', 'Customer') && iu.customer"
                            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                            variant="link"
                            class="weight-600 align-middle detail-view-id text-uppercase"
                            :to="{ name: 'customers-customers-show', params: { id: iu.customer._id } }"
                          >
                            {{ iu.name }}
                          </b-button>
                          <b-button
                            v-else
                            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                            variant="none"
                            class="text-bold-black align-middle not-button text-uppercase"
                          >
                            {{ iu.name }}
                          </b-button>
                          <b-badge
                            v-if="iu.isPurchaser"
                            variant="light-info"
                            class="ml-50"
                          >
                            {{ iu.purchaserNo }}
                          </b-badge>
                          <br>
                          <span>{{ iu.nric }}</span>
                          <br>
                          <span>{{ iu.relation || '-' }}</span>
                          <br>
                          <span>
                            <feather-icon
                              icon="TabletIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            {{ iu.contact || '-' }}
                          </span>
                          <br>
                          <span>
                            <feather-icon
                              icon="CalendarIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            {{ (iu.customer && iu.customer.dob) ? dateFormat(iu.customer.dob) : '-' }}
                          </span>
                          <br>
                          <span>
                            <feather-icon
                              icon="MapPinIcon"
                              size="22"
                              class="mr-25 iu-icon"
                            />
                            <span>
                              {{ getResidentialAddress(iu) }}
                            </span>
                          </span>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                  <div
                    v-else
                    class="empty_block"
                  >
                    <h4>No Data Yet</h4>
                    <span class="align-middle">Item will show once the data is entered</span>
                  </div>
                </b-card-text>
              </b-tab>
              <template #tabs-end>
                <div class="text-right ml-auto vendor-detail-more-icon view-more-icon">
                  <b-button
                    variant="link"
                    :to="{ name: 'customers-files-nominees', params: { id: $route.params.id } }"
                  >
                    View more <feather-icon icon="ArrowRightIcon" />
                  </b-button>
                </div>
              </template>
            </b-tabs>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      class="match-height"
    >
      <b-col
        md="6"
      >
        <b-card
          title="Related Deceased Data (TBC)"
          class="has-padding"
        >
          <div class="empty_block">
            <h4>No Data Yet</h4>
            <span class="align-middle">Item will show once the data is entered</span>
          </div>
        </b-card>
      </b-col>
      <b-col
        md="6"
      >
        <b-card
          class="has-padding"
        >
          <b-card-title>
            Agent Details
          </b-card-title>
          <b-card-body>
            <b-tabs
              v-if="fileInfo.agents && fileInfo.agents.length > 1"
              class="purchaser-tabs"
              content-class="mt-2"
            >
              <b-tab
                v-for="(agent, key) in fileInfo.agents"
                :key="'agent' + key"
                :title="agent.name"
              >
                <b-card-text>
                  <div class="d-flex gap-5">
                    <div>
                      <b-avatar
                        v-if="agent.image"
                        :src="agent.image"
                        size="150px"
                      />
                      <b-avatar
                        v-else
                        :text="resolveAcronymName(agent.name)"
                        size="150px"
                      />
                    </div>
                    <div>
                      <b-badge
                        variant="light-info"
                      >
                        {{ agent.type }}
                      </b-badge>
                      <br>
                      <span>
                        <feather-icon
                          icon="UserIcon"
                          size="22"
                          class="mr-25 iu-icon"
                        />
                        <b-button
                          v-if="canViewThisAction('show', 'Agent') && agent.agent"
                          v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                          variant="link"
                          class="weight-600 align-middle detail-view-id"
                          :to="{ name: 'agency-agents-show', params: { id: agent.agent._id } }"
                        >
                          {{ agent.name }}
                        </b-button>
                        <b-button
                          v-else
                          v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                          variant="none"
                          class="text-bold-black align-middle not-button"
                        >
                          {{ agent.name }}
                        </b-button>
                        ({{ agent.code || '-' }})
                      </span>
                      <br>
                      <span>
                        <feather-icon
                          icon="TabletIcon"
                          size="22"
                          class="mr-25 iu-icon"
                        />
                        <span>
                          {{ agent.contact || '-' }}
                        </span>
                      </span>
                      <p class="agency-text">
                        <span class="text-bold-black">Agency</span>
                        <span class="ml-1">{{ agent.agent ? agent.agent.agencyName : '-' }} ({{ agent.agent ? agent.agent.agencyCode : '-' }}) - {{ agent.agent ? agent.agent.agencyCountry : '-' }}</span>
                      </p>
                    </div>
                  </div>
                  <hr>
                  <p class="agency-text weight-500">
                    Other files under this agent for main purchaser
                  </p>
                  <span
                    v-for="(file, index) in agent.files"
                    :key="'agent-file' + index"
                    class="mr-50 mb-50"
                    style="display: inline-block;"
                  >
                    <b-button
                      :id="'tooltip-0-' + index"
                      variant="primary"
                    >
                      {{ file.fileNo }}
                    </b-button>
                    <b-tooltip
                      :target="'tooltip-0-' + index"
                      triggers="hover"
                      no-fade
                    >
                      <span>{{ file.nvsFileType }}</span>
                      <br>
                      <span>{{ file.lotLocation }}</span>
                    </b-tooltip>
                  </span>
                </b-card-text>
              </b-tab>
            </b-tabs>
            <b-card-text v-else-if="fileInfo.agents && fileInfo.agents.length">
              <div class="d-flex gap-5">
                <div>
                  <b-avatar
                    v-if="fileInfo.agents[0].image"
                    :src="fileInfo.agents[0].image"
                    size="150px"
                  />
                  <b-avatar
                    v-else
                    :text="resolveAcronymName(fileInfo.agents[0].name)"
                    size="150px"
                  />
                </div>
                <div>
                  <b-badge
                    variant="light-info"
                  >
                    {{ fileInfo.agents[0].type }}
                  </b-badge>
                  <br>
                  <span>
                    <feather-icon
                      icon="UserIcon"
                      size="22"
                      class="mr-25 iu-icon"
                    />
                    <b-button
                      v-if="canViewThisAction('show', 'Agent') && fileInfo.agents[0].agent"
                      v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                      variant="link"
                      class="weight-600 align-middle detail-view-id"
                      :to="{ name: 'agency-agents-show', params: { id: fileInfo.agents[0].agent._id } }"
                    >
                      {{ fileInfo.agents[0].name }}
                    </b-button>
                    <b-button
                      v-else
                      v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                      variant="none"
                      class="text-bold-black align-middle not-button"
                    >
                      {{ fileInfo.agents[0].name }}
                    </b-button>
                    ({{ fileInfo.agents[0].code || '-' }})
                  </span>
                  <br>
                  <span>
                    <feather-icon
                      icon="TabletIcon"
                      size="22"
                      class="mr-25 iu-icon"
                    />
                    <span>
                      {{ fileInfo.agents[0].contact || '-' }}
                    </span>
                  </span>
                  <p class="agency-text">
                    <span class="text-bold-black">Agency</span>
                    <span class="ml-1">{{ fileInfo.agents[0].agent ? fileInfo.agents[0].agent.agencyName : '-' }} ({{ fileInfo.agents[0].agent ? fileInfo.agents[0].agent.agencyCode : '-' }}) - {{ fileInfo.agents[0].agent ? fileInfo.agents[0].agent.agencyCountry : '-' }}</span>
                  </p>
                </div>
              </div>
              <hr>
              <p class="agency-text weight-500">
                Other files under this agent for main purchaser
              </p>
              <span
                v-for="(file, index) in fileInfo.agents[0].files"
                :key="'agent-file' + index"
                class="mr-50 mb-50"
                style="display: inline-block;"
              >
                <b-button
                  :id="'tooltip-0-' + index"
                  variant="primary"
                >
                  {{ file.fileNo }}
                </b-button>
                <b-tooltip
                  :target="'tooltip-0-' + index"
                  triggers="hover"
                  no-fade
                >
                  <span>{{ file.nvsFileType }}</span>
                  <br>
                  <span>{{ file.lotLocation }}</span>
                </b-tooltip>
              </span>
            </b-card-text>
            <div
              v-else
              class="empty_block"
            >
              <h4>No Data Yet</h4>
              <span class="align-middle">Item will show once the data is entered</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BRow, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BButton, VBTooltip, VBToggle,
  BTabs, BTab, BCardText, BDropdown, BDropdownItem, BBadge, BAvatar, BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    BCardText,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BBadge,
    BAvatar,
    BTooltip,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
    fileInfo: {
      type: [Object, null],
      default: () => {},
    },
  },
  data() {
    return {
      relatedLot: {},
    }
  },
  watch: {
    fileInfo() {
      if (this.fileInfo.relatedLots && this.fileInfo.relatedLots.length) {
        // eslint-disable-next-line prefer-destructuring
        this.relatedLot = this.fileInfo.relatedLots[0]
      }
    },
  },
  methods: {
    canViewThisAction,
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    editUser(user, index) {
      this.$emit('emitIntendedUserEdit', user, index)
    },
    editRemarks() {
      this.$emit('emitRemarksEdit')
    },
    removeUser(user, index) {
      this.$emit('emitIntendedUserRemove', user, index)
    },
    getResidentialAddress(purchaser) {
      if (purchaser.customer) {
        let residentialAddress = ''
        residentialAddress += purchaser.customer.perAddress1 ? purchaser.customer.perAddress1 : ''
        residentialAddress += purchaser.customer.perAddress2 ? `, ${purchaser.customer.perAddress2}` : ''
        residentialAddress += purchaser.customer.perCountry ? `, ${purchaser.customer.perCountry}` : ''
        residentialAddress += purchaser.customer.perPostcode ? ` ${purchaser.customer.perPostcode}` : ''
        return residentialAddress
      }

      return '-'
    },
  },
}
</script>
