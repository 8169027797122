<template>

  <div class="service_form_details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="4"
        lg="4"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ fileDetail.fileNo }} - {{ fileDetail.nvsFileType }}
            </h1>
            <p class="log_info">
              File created by {{ getCreatedBy() }} on {{ getCreatedAt() }}<br>Last updated on {{ getUpdatedAt() }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right mb-1"
        md="8"
        lg="8"
        cols="12"
      >
        <div class="user_block file_payment_type user__block-margin-adjust">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Payment Status</h6>
            <h5
              class="text-capitalize"
              :class="`text-${resolvePaymentStatusVariant(fileDetail.paymentStatus)}`"
            >
              {{ fileDetail.paymentStatus ? fileDetail.paymentStatus : '' }}
            </h5>
          </div>
        <!-- <div class="action_button">
          <b-button
            v-if="canViewThisAction('update', 'ServiceRequest') || canViewThisAction('update-status', 'ServiceRequest')"
            v-b-modal.serviceRequest-all-modal-update-status
            variant="flat-primary"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
            variant="flat-primary"
            style="opacity: 0.65;"
            aria-readonly=""
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
          </b-button>
        </div> -->
        </div>
        <div class="user_block pr_stage_type user__block-margin-adjust">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>File Status</h6>
            <h5
              class="text-capitalize"
              :class="`text-${resolveFileStatusVariant(fileDetail.nvsFileStatus)}`"
            >
              {{ fileDetail.nvsFileStatus ? fileDetail.nvsFileStatus.toLowerCase() : '' }}
            </h5>
          </div>
          <!-- <div class="action_button">
            <b-button
              v-if="canViewThisAction('update', 'ServiceRequest') || canViewThisAction('update-status', 'ServiceRequest')"
              v-b-modal.serviceRequest-all-modal-update-status
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
            <b-button
              v-else
              v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
              variant="flat-primary"
              style="opacity: 0.65;"
              aria-readonly=""
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div> -->
        </div>
        <div class="user_block user__block-margin-adjust">
          <div class="user_avatar">
            <img :src="getAgentImage(fileDetail.agents)">
          </div>
          <div class="user_info">
            <h6>Service Agent</h6>
            <h5>{{ getAgentName(fileDetail.agents) }}</h5>
          </div>
          <!-- <div class="action_button">
              <b-button
                v-if="canViewThisAction('update', 'ServiceRequest') || canViewThisAction('update-assignee', 'ServiceRequest')"
                variant="flat-primary"
                @click="setDutyOfficerModal()"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                  size="16"
                />
              </b-button>
              <b-button
                v-else
                v-b-tooltip.hover.bottom="'You don’t have permission to edit this information.'"
                variant="flat-primary"
                style="opacity: 0.65;"
                aria-readonly=""
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                  size="16"
                />
              </b-button>
            </div> -->
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-tabs
      v-model="tabIndex"
      pills
      class="dashboard__tabs file_detail"
    >
      <b-alert
        variant="danger"
        :show="fileDetail.ledgerSummary && fileDetail.ledgerSummary.reactivationFee"
        class="file-alert danger__alert"
      >
        <div class="alert-body has-radius">
          <span>
            <feather-icon
              size="20"
              icon="AlertTriangleIcon"
              class="mr-25"
            /> <div class="alert_text"><strong>Payment Required!</strong> This file is currently in-active due to un-paid of late payment charges. To re-activate this file, customer are required to pay all the late payment charges.</div>
          </span>
        </div>
      </b-alert>
      <b-alert
        variant="warning"
        :show="fileDetail && fileDetail.exerciseStatus"
        class="file-alert"
      >
        <div class="alert-body has-radius">
          <span v-if="fileDetail.nvsFileType == 'Funeral Service Package'">
            <feather-icon
              size="20"
              icon="CalendarIcon"
              class="mr-25"
            /> This file has been exercised from {{ fileDetail.exerciseDate ? dateFormat(fileDetail.exerciseDate) : '' }}
          </span>
          <span v-else-if="fileDetail.nvsFileType == 'Urn'">
            <feather-icon
              size="20"
              icon="CalendarIcon"
              class="mr-25"
            /> This file has been exercised on {{ fileDetail.exerciseDate ? dateFormat(fileDetail.exerciseDate) : '' }}
          </span>
          <span v-else>
            <feather-icon
              size="20"
              icon="CheckIcon"
              class="mr-25"
            /> This file has been exercised.
          </span>
        </div>
      </b-alert>
      <b-tab
        key="details"
      >
        <template #title>
          <b-card
            no-body
            class="dashboard__tab-card"
          >
            <b-card-body
              class="d-flex align-items-center"
            >
              <b-avatar
                variant="light-primary"
                size="40"
                rounded
              >
                <feather-icon
                  size="20"
                  icon="ListIcon"
                  class="mr-0"
                />
              </b-avatar>
              <div class="truncate ml-2">
                <h2>File Details</h2>
              </div>
            </b-card-body>
          </b-card>
        </template>
        <FileInfo
          :file-info="fileDetail"
          @emitRemarksEdit="fileRemarksEdit"
          @emitIntendedUserEdit="intendedUserEdit"
          @emitIntendedUserRemove="intendedUserRemove"
        />
      </b-tab>
      <b-tab
        key="payment"
        lazy
      >
        <template #title>
          <b-card
            no-body
            class="dashboard__tab-card"
          >
            <b-card-body
              class="d-flex align-items-center"
            >
              <b-avatar
                variant="light-primary"
                size="40"
                rounded
              >
                <feather-icon
                  size="20"
                  icon="CreditCardIcon"
                  class="mr-0"
                />
              </b-avatar>
              <div class="truncate ml-2">
                <h2>Payment Information</h2>
              </div>
              <b-button
                v-if="canViewThisAction('register-payment', 'File')"
                v-b-tooltip.hover
                variant="flat-primary"
                class="ml-auto"
                title="Register Payment"
                :to="{ name: 'customers-register-payment', query: { fileID: fileDetail.fileID } }"
              >
                <feather-icon
                  size="20"
                  icon="DollarSignIcon"
                />
              </b-button>
            </b-card-body>
          </b-card>
        </template>
        <b-card class="dashboard__tab-content-card payment-summary">
          <b-card
            title="Payment Summary"
            class="padding-20"
          >
            <b-row>
              <b-col
                md="7"
                lg="7"
                sm="6"
                class="overview__option-block"
              >
                <div class="overview__option-result-budget-amount card-statistics statistics-body">
                  <b-row>
                    <b-col md="6">
                      <b-media
                        no-body
                        class="m-25"
                      >
                        <b-media-aside
                          class="mr-1"
                        >
                          <b-img
                            width="48"
                            height="48"
                            :src="require('@/assets/images/admin/page_blue.png')"
                            rounded="circle"
                            alt="Circle image"
                          />
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="weight-500 h4-color mb-0">
                            {{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.totalDN) ? `$${numberFormat(fileDetail.ledgerSummary.totalDN)}` : '-' }}
                          </h4>
                          <b-card-text class="text-muted-o mb-0">
                            Total Debit Notes
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <b-col md="6">
                      <b-media
                        no-body
                        class="m-25"
                      >
                        <b-media-aside
                          class="mr-1"
                        >
                          <b-img
                            width="48"
                            height="48"
                            :src="require('@/assets/images/admin/dollar_blue.png')"
                            rounded="circle"
                            alt="Circle image"
                          />
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="weight-500 h4-color mb-0">
                            {{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.totalBilling) ? `$${numberFormat(fileDetail.ledgerSummary.totalBilling)}` : '-' }}
                          </h4>
                          <b-card-text class="text-muted-o mb-0">
                            Total Billings
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <b-col md="6">
                      <b-media
                        no-body
                        class="m-25"
                      >
                        <b-media-aside
                          class="mr-1"
                        >
                          <b-img
                            width="48"
                            height="48"
                            :src="require('@/assets/images/admin/page_green.png')"
                            rounded="circle"
                            alt="Circle image"
                          />
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="weight-500 h4-color mb-0">
                            {{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.totalCN) ? `$${numberFormat(fileDetail.ledgerSummary.totalCN)}` : '-' }}
                          </h4>
                          <b-card-text class="text-muted-o mb-0">
                            Total Credit Notes
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <b-col md="6">
                      <b-media
                        no-body
                        class="m-25"
                      >
                        <b-media-aside
                          class="mr-1"
                        >
                          <b-img
                            width="48"
                            height="48"
                            :src="require('@/assets/images/admin/budget_icon.png')"
                            rounded="circle"
                            alt="Circle image"
                          />
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="weight-500 h4-color mb-0">
                            {{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.totalPayment) ? `$${numberFormat(fileDetail.ledgerSummary.totalPayment)}` : '-' }}
                          </h4>
                          <b-card-text class="text-muted-o mb-0">
                            Total Payment
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <b-col md="6">
                      <b-media
                        no-body
                        class="m-25"
                      >
                        <b-media-aside
                          class="mr-1"
                        >
                          <b-img
                            width="48"
                            height="48"
                            :src="require('@/assets/images/admin/dollar_red.png')"
                            rounded="circle"
                            alt="Circle image"
                          />
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="weight-500 h4-color mb-0">
                            {{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.osExclReactivation) ? `$${numberFormat(fileDetail.ledgerSummary.osExclReactivation)}` : '-' }}
                          </h4>
                          <b-card-text class="text-muted-o mb-0">
                            Outstanding Less Re. Fees
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <b-col md="6">
                      <b-media
                        no-body
                        class="m-25"
                      >
                        <b-media-aside
                          class="mr-1"
                        >
                          <b-img
                            width="48"
                            height="48"
                            :src="require('@/assets/images/admin/dollar_red.png')"
                            rounded="circle"
                            alt="Circle image"
                          />
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="weight-500 h4-color mb-0">
                            {{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.reactivationFee) ? `$${numberFormat(fileDetail.ledgerSummary.reactivationFee)}` : '-' }}
                          </h4>
                          <b-card-text class="text-muted-o mb-0">
                            Reactivation Fees
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <b-col md="6">
                      <b-media
                        no-body
                        class="m-25"
                      >
                        <b-media-aside
                          class="mr-1"
                        >
                          <b-img
                            width="48"
                            height="48"
                            :src="require('@/assets/images/admin/dollar_yellow.png')"
                            rounded="circle"
                            alt="Circle image"
                          />
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="weight-500 h4-color mb-0">
                            {{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.outstanding) ? `$${numberFormat(fileDetail.ledgerSummary.outstanding)}` : '-' }}
                          </h4>
                          <b-card-text class="text-muted-o mb-0">
                            Total Outstanding
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <b-col md="6">
                      <b-media
                        no-body
                        class="m-25"
                      >
                        <b-media-aside
                          class="mr-1"
                        >
                          <b-img
                            width="48"
                            height="48"
                            :src="require('@/assets/images/admin/clock_yellow.png')"
                            rounded="circle"
                            alt="Circle image"
                          />
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="weight-500 h4-color mb-0">
                            {{ fileDetail.installmentSummary ? fileDetail.installmentSummary.remainingTenure : '-' }}
                          </h4>
                          <b-card-text class="text-muted-o mb-0">
                            Remaining Tenure
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col
                md="5"
                lg="5"
                sm="6"
                class="pl-3"
              >
                <b-table-simple
                  borderless
                  responsive
                  class="particulars_of_deceased d-none d-md-block "
                >
                  <b-tbody>
                    <b-tr>
                      <b-th width="230px">
                        Payment Method
                      </b-th>
                      <b-td>{{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.paymentMethod) ? fileDetail.ledgerSummary.paymentMethod : '-' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Purchase Date
                      </b-th>
                      <b-td>{{ fileDetail.confirmDate ? dateFormat(fileDetail.confirmDate) : '-' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Purchase Price
                      </b-th>
                      <b-td>{{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.totalBilling) ? `$${numberFormat(fileDetail.ledgerSummary.totalBilling)}` : '-' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        GST
                      </b-th>
                      <b-td>{{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.totalGST) ? `$${numberFormat(fileDetail.ledgerSummary.totalGST)}` : '-' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Discounts
                      </b-th>
                      <b-td>{{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.discount) ? `$${numberFormat(fileDetail.ledgerSummary.discount)}` : '-' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        GST Rebate
                      </b-th>
                      <b-td>{{ (fileDetail.ledgerSummary && fileDetail.ledgerSummary.gstRebate) ? `$${numberFormat(fileDetail.ledgerSummary.gstRebate)}` : '-' }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
          <b-card
            title=""
            class="padding-20"
          >
            <b-card-title class="d-flex">
              <span>Related Ledger Data</span>
              <b-button
                v-if="canViewThisAction('register-payment', 'File')"
                variant="link"
                class="ml-auto"
                :to="{ name: 'customers-register-payment', query: { fileID: fileDetail.fileID } }"
              >
                Register Payment
              </b-button>
            </b-card-title>
            <b-card-text>
              <b-tabs
                v-model="tabIndexCredit"
                class="purchaser-tabs"
                content-class="mt-2"
              >
                <b-tab
                  title="Billings & Debit Notes"
                >
                  <BillingList
                    :invoices="invoices"
                    :ledger-summary="fileDetail.ledgerSummary"
                  />
                </b-tab>
                <b-tab
                  lazy
                  title="Receipt & Credit Notes"
                >
                  <ReceiptList
                    :receipt-data="receipts"
                    :ledger-summary="fileDetail.ledgerSummary"
                  />
                </b-tab>
                <b-tab
                  lazy
                  title="Allocation"
                >
                  <AllocationList
                    :debit-allocation="fileDetail.debitAllocations"
                    :credit-allocation="fileDetail.creditAllocations"
                  />
                </b-tab>
              </b-tabs>
            </b-card-text>
          </b-card>
          <b-card
            v-if="fileDetail.hasInstallment"
            title="Installment Schedule"
            class="padding-20"
          >
            <b-card-text>
              <InstallmentSchedule
                :installment-summary="fileDetail.installmentSummary"
                :ledger-summary="fileDetail.ledgerSummary"
                :installment-data="fileDetail.installmentSchedule"
              />
            </b-card-text>
          </b-card>
        </b-card>
      </b-tab>
      <template #tabs-end>
        <div class="text-right ml-auto vendor-detail-more-icon file_detail_tab_end">
          <b-dropdown
            variant="flat-primary"
            no-caret
            class="px-1"
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('register-payment', 'File')"
              :to="{ name: 'customers-register-payment', query: { fileID: fileDetail.fileID } }"
            >
              <feather-icon icon="DollarSignIcon" />
              <span class="align-middle ml-50">Register Payment</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canViewThisAction('send-email-statement', 'File')"
              @click="openEmailStatementModal"
            >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">Email Statement of Account to Customer</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-tabs>

    <b-card
      title="Attachments"
      class="mb-3 mt-3 p-2"
    >
      <input
        ref="hiddenAllocationFileInput"
        type="file"
        multiple
        capture
        accept="image/jpeg, image/png, application/pdf"
        class="d-none"
        @change="setAttachment"
      >
      <b-row v-if="fileDetail.attachments && fileDetail.attachments.length">
        <!-- Content Left -->
        <b-col
          v-for="(file, key) in fileDetail.attachments"
          :key="key"
          class="content-header-left mb-2"
          cols="12"
          md="4"
        >
          <div
            class="attachment-item"
          >
            <b-img
              v-if="file.type.includes('image')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_jpg.png')"
              alt="Left image"
            />
            <b-img
              v-if="file.type.includes('application')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_file.png')"
              alt="Left image"
            />
            <strong>
              <b-link
                :href="file ? file.data : ''"
                target="_blank"
              >
                {{ file ? file.name : '' }}
              </b-link>
            </strong>
            <span>{{ file ? `${file.size}mb` : '' }}</span>
            <span class="remove__doc">
              <b-button
                v-if="canViewThisAction('update', 'File')"
                variant="flat-primary"
                @click="removeAttachment(file.name, file.data)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span>
          </div>
          <div class="doc__desc new_design">
            <span v-if="file.description">
              {{ file.description }}
            </span>
            <span v-else>
              <button
                v-if="canViewThisAction('update', 'File')"
                @click="updateDescription(key)"
              >
                Add a description
              </button>
            </span>
            <span
              v-if="file.description"
              class="edit__desc"
            >
              <b-button
                v-if="canViewThisAction('update', 'File')"
                variant="flat-primary"
                @click="updateDescription(key)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span>
          </div>
        </b-col>
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="4"
        >
          <b-button
            v-if="canViewThisAction('update', 'File')"
            variant="link"
            class="mr-1 md-mb-1"
            @click="$refs.hiddenAllocationFileInput.click()"
          >
            <feather-icon
              icon="UploadIcon"
              size="20"
              class="mr-50"
            />
            <span
              class="align-middle"
              style="font-weight: 500; font-size: 14px;"
            >
              Upload
            </span>
          </b-button>
          <b-button
            v-if="attachments.length"
            variant="success"
            class="mr-1 md-mb-1"
            @click="uploadFile"
          >
            Save File
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <div class="empty_block">
            <h4>Nothing in here yet</h4>
            <b-button
              v-if="canViewThisAction('update', 'File')"
              variant="link"
              class=""
              @click="$refs.hiddenAllocationFileInput.click()"
            >
              <feather-icon
                icon="UploadIcon"
                size="20"
                class="mr-50"
              />
              <span
                class="align-middle"
                style="font-weight: 500; font-size: 14px;"
              >
                Upload
              </span>
            </b-button>
            <b-button
              v-if="attachments.length"
              variant="success"
              class="mr-1 md-mb-1"
              @click="uploadFile"
            >
              Save File
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Related to this File"
      class="mb-3 mt-3 p-2"
    >
      <b-card-text>
        <b-tabs
          class="purchaser-tabs"
          content-class="mt-2"
        >
          <b-tab
            v-if="canViewThisAction('read', 'File')"
            title="Files"
          >
            <RelatedFilesList :related-files="fileDetail.relatedFiles" />
          </b-tab>
          <b-tab
            v-if="canViewThisAction('read', 'ServiceRequest')"
            lazy
            title="Service Requests"
          >
            <RelatedServiceRequests :service-requests="[]" />
          </b-tab>
          <b-tab
            v-if="canViewThisAction('read', 'InventoryRequest')"
            lazy
            title="Inventory Requests"
          >
            <RelatedInventoryRequests :inventory-requests="[]" />
          </b-tab>
          <b-tab
            v-if="canViewThisAction('read', 'Invoice')"
            lazy
            title="Invoices"
          >
            <RelatedInvoices
              :related-invoices="invoices"
              :file-type="fileDetail.nvsFileType"
            />
          </b-tab>
        </b-tabs>
      </b-card-text>
    </b-card>

    <b-card
      class="mb-3 mt-3 p-2"
    >
      <b-row
        class="content-header"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left pb-2"
          cols="12"
          md="12"
        >
          <h4>
            <feather-icon
              icon="ListIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Activity Logs</span>
          </h4>
        </b-col>
      </b-row>
      <app-timeline>
        <!-- FINANCIAL REPORT -->
        <app-timeline-item
          v-for="(opt, key) in fileDetailActivityLogs"
          :key="key"
          :variant="getVariant(key)"
        >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ opt.title }}</h6>
            <small class="text-muted">{{ dateFormatWithTime(opt.time) }}</small>
          </div>
          <b-button
            v-if="opt.activityDetails && opt.activityDetails.length"
            v-b-toggle="'report-list-with-icon-' + key"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-primary"
          >
            Details
            <feather-icon
              icon="ChevronUpIcon"
              class="ml-50 when-open"
              size="16"
            />
            <feather-icon
              icon="ChevronDownIcon"
              class="ml-50 when-closed"
              size="16"
            />
          </b-button>
          <b-collapse
            v-if="opt.activityDetails && opt.activityDetails.length"
            :id="'report-list-with-icon-' + key"
          >
            <b-table-simple
              borderless
              responsive
            >
              <b-tbody>
                <b-tr
                  v-for="(activityDetail, activitykey) in opt.activityDetails"
                  :key="activitykey"
                >
                  <b-th style="width: 300px; max-width: 300px;">
                    {{ activityDetail.title }}
                  </b-th>
                  <b-td>{{ activityDetail.oldValue }}</b-td>
                  <b-td>{{ activityDetail.newValue }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-collapse>
        </app-timeline-item>
      </app-timeline>
    </b-card>

    <b-modal
      id="modal-attachment-description"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Payment Schedule"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-file-remarks"
      size="sm"
      title="Edit Remarks"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      footer-class="iu-edit-modal-footer"
      @ok="saveFileRemarks"
      @cancel="closeFileRemarksModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="fileRemarks"
            rows="6"
            placeholder="E.g. Payment Schedule"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!fileRemarks"
          @click="ok()"
        >
          <span class="align-middle">Save Changes</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-update-intended-user"
      ref="update_intended_user_modal"
      modal-class="status-update-modal iu-modal"
      footer-class="iu-edit-modal-footer"
      cancel-variant="flat-primary"
      ok-variant="primary"
      ok-title="Save Changes"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      :title="editModalTitle"
      size="sm"
      @ok="intendedUserSubmitForm"
    >
      <b-form @submit.prevent="intendedUserSubmitForm">
        <validation-observer
          ref="intendedUserEditForm"
        >
          <b-form-group
            label="Name*"
            label-for="h-iu-iuName"
            description="These fields can be updated in the entity’s record."
          >
            <b-form-input
              id="h-iu-iuName"
              v-model="iuName"
              placeholder="Name"
              name="iuName"
              disabled
              class="text-uppercase"
            />
          </b-form-group>
          <b-form-group
            label="NRIC*"
            label-for="h-iu-iuNRIC"
          >
            <b-form-input
              id="h-iu-iuNRIC"
              v-model="iuNRIC"
              placeholder="NRIC"
              name="iuNRIC"
              disabled
            />
          </b-form-group>
          <b-form-group
            label="Date of Birth*"
            label-for="h-iu-iuDOB"
          >
            <b-form-input
              id="h-iu-iuDOB"
              v-model="iuDOB"
              placeholder="Date of Birth"
              name="iuDOB"
              disabled
            />
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Relationship"
            vid="iuRelationship"
            rules="required"
          >
            <b-form-group
              label="Relationship*"
              label-for="h-iu-iuRelationship"
              :state="(errors.length > 0) ? false : null"
            >
              <v-select
                id="h-iu-iuRelationship"
                v-model="iuRelationship"
                :options="relationshipOptions"
                :clearable="false"
                placeholder="Select an option"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="Mobile No.*"
            label-for="h-iu-iuContact"
            rules="required"
          >
            <validation-provider
              name="Mobile No."
              rules="required"
            >
              <cleave
                id="h-iu-iuContact"
                v-model="iuContact"
                class="form-control"
                :raw="false"
                :options="options.prefix"
                @input="iuContactValidation = false"
              />
              <small
                v-if="iuContactValidation"
                class="text-danger display-block"
              >
                {{ iuContactError }}
              </small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Residential Address*"
            label-for="h-iu-iuAdd1"
          >
            <validation-provider
              #default="{ errors }"
              name="Address Line 1"
              rules="required"
            >
              <b-form-input
                id="h-iu-iuAdd1"
                v-model="iuAdd1"
                placeholder="Address Line 1"
                name="iuAdd1"
                rules="required"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label-for="h-iu-iuAdd2"
          >
            <validation-provider
              #default="{ errors }"
              name="Address Line 2"
              rules="required"
            >
              <b-form-input
                id="h-iu-iuAdd2"
                v-model="iuAdd2"
                placeholder="Address Line 2"
                name="iuAdd2"
                rules="required"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Select a country"
            rules="required"
          >
            <b-form-group
              label-for="h-iu-iuCountry"
              :state="(errors.length > 0) ? false : null"
            >
              <v-select
                id="h-iu-iuCountry"
                v-model="iuCountry"
                rules="required"
                :options="countryOptions"
                :clearable="false"
                placeholder="Select a country"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label-for="h-iu-iuPostCode"
          >
            <validation-provider
              #default="{ errors }"
              name="Post Code"
              rules="required"
            >
              <b-form-input
                id="h-iu-iuPostCode"
                v-model="iuPostCode"
                placeholder="Post Code"
                name="iuPostCode"
                rules="required"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          <span class="align-middle">Save Changes</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="email-statement-modal-detail-page"
      ref="email-statement-modal-detail-page"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Email Statement of Account to Customer"
      @ok="sendEmailStatement"
      @cancel="closeStatementModal"
    >
      <div class="">
        <b-form-group
          label="Customer Name"
          label-for="customer-name"
          class="mb-20"
        >
          <validation-provider
            #default="{ errors }"
            name="Customer Name"
            vid="customer-name"
            rules=""
          >
            <b-form-input
              id="customer-name"
              v-model="customerNameVal"
              placeholder="Lin Chai"
              class="mb-0 text-uppercase"
              readonly
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Email Address"
          label-for="customer-email"
          class="mb-20"
        >
          <validation-provider
            #default="{ errors }"
            name="Email Address"
            vid="customer-email"
            rules="required"
          >
            <b-form-input
              id="customer-email"
              v-model="customerEmailVal"
              placeholder="email@domain.com"
              class="mb-0"
              :readonly="hasEmailAddress ? true : false"
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Message"
          label-for="statement-message"
          class="mb-20"
        >
          <validation-provider
            #default="{ errors }"
            name="Message"
            vid="statement-message"
            rules="required"
          >
            <b-form-textarea
              id="statement-message"
              v-model="statementMessage"
              placeholder="Statement of Account"
              class="mb-0"
              rows="9"
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="!customerEmailVal"
          @click="ok()"
        >
          <feather-icon
            icon="SendIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Send</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardText, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, VBToggle, VBTooltip, BCollapse, BTab, BTabs, BCardBody, BAvatar,
  BDropdown, BDropdownItem, BImg, BFormTextarea, BLink, BForm, BFormGroup, BFormInput, BAlert, BMedia, BMediaAside, BMediaBody, BCardTitle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store/index'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ToastificationContentCustom from '../../operation/ToastificationContentCustom.vue'
import FileInfo from './FileInfo.vue'
import RelatedFilesList from './RelatedFilesList.vue'
import RelatedServiceRequests from './RelatedServiceRequests.vue'
import RelatedInventoryRequests from './RelatedInventoryRequests.vue'
import RelatedInvoices from './RelatedInvoices.vue'
import ReceiptList from './ReceiptList.vue'
import BillingList from './BillingList.vue'
import AllocationList from './AllocationList.vue'
import InstallmentSchedule from './InstallmentSchedule.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCollapse,
    BTab,
    BTabs,
    BCardBody,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BImg,
    BFormTextarea,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BAlert,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardTitle,

    vSelect,
    Cleave,

    FileInfo,
    RelatedFilesList,
    RelatedServiceRequests,
    RelatedInventoryRequests,
    RelatedInvoices,
    ReceiptList,
    BillingList,
    AllocationList,
    InstallmentSchedule,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      fileDetail: {},
      receipts: [],
      invoices: [],
      // fileDetail: JSON.parse(localStorage.getItem('customerFileDetail')),
      // receipts: JSON.parse(localStorage.getItem('customerFileDetailReceipts')),
      // invoices: JSON.parse(localStorage.getItem('customerFileDetailInvoices')),
      fileDetailID: '',
      fileDetailActivityLogs: [],
      required,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
      currentTabTitle: 'File Details',
      activeTab: 0,
      attachments: [],
      description: '',
      attachmentKey: '',
      iuIndexID: '',
      iuID: '',
      iuType: '',
      formattedIUType: '',
      iuIndex: '',
      iuTypeOnly: '',
      iuName: '',
      iuNRIC: '',
      iuDOB: '',
      iuRelationship: '',
      iuContact: '',
      iuAdd1: '',
      iuAdd2: '',
      iuAdd3: '',
      iuCountry: '',
      iuPostCode: '',
      fileRemarks: '',
      customerNameVal: '',
      customerEmailVal: '',
      statementMessage: '',
      relationshipOptions: [
        'Brother', 'Brother in-law', 'Cousin', 'Daughter', 'Daughter in-law', 'Elder Brother', 'Father', 'Father in-law',
        'Friend', 'God-father', 'Grand Daughter', 'Grandfather', 'Grandmother', 'Grandson', 'Great Grand Father',
        'Great Grand Mother', 'Husband', 'Mother', 'Mother in-law', 'N/A', 'Nephew', 'Niece', 'Relative', 'Self', 'Sister',
        'Sister in-law', 'Son', 'Son in-law', 'Uncle', 'Wife',
      ],
      countryOptions: ['Singapore', 'Malaysia'],
      editModalTitle: 'Edit Intended User',
      iuContactValidation: false,
      iuContactError: 'The Mobile No. field is required',
      options: {
        prefix: {
          numericOnly: true,
          prefix: '+65',
          blocks: [3, 8],
        },
      },
      tabIndex: 0,
      tabIndexCredit: 0,
      hasEmailAddress: '',
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.fetchFileDetail()
      },
    },
  },
  mounted() {
    window.scrollTo(0, 0)
    this.fetchFileDetail()
    // document.title = `${this.fileDetail.fileNo} - ${this.fileDetail.nvsFileType} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
    // store.commit('breadcrumb/REMOVE_BREADCRUMB')
    // const newMeta = {
    //   active: true,
    //   text: `${this.fileDetail.fileNo} - ${this.fileDetail.nvsFileType}`,
    // }
    // store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
    // this.fileDetailActivityLogs = this.fileDetail.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
  },
  methods: {
    getCreatedBy() {
      if (this.fileDetail.createdByNvs) {
        return this.fileDetail.createdByNvs.name
      }
      if (this.fileDetail.createdBy) {
        return this.fileDetail.createdBy
      }

      return ''
    },
    getCreatedAt() {
      if (this.fileDetail.createdAtNvs) {
        return this.dateFormatWithTime(this.fileDetail.createdAtNvs)
      }
      if (this.fileDetail.createdAt) {
        return this.dateFormatWithFormatNTZ(this.fileDetail.createdAt, 'DD/MM/YYYY, hh:mma ')
      }

      return ''
    },
    getUpdatedAt() {
      if (this.fileDetail.updatedAtNvs) {
        return this.dateFormatWithTime(this.fileDetail.updatedAtNvs)
      }
      if (this.fileDetail.createdBy) {
        return this.dateFormatWithFormatNTZ(this.fileDetail.updatedAt, 'DD/MM/YYYY, hh:mma ')
      }

      return ''
    },

    fetchFileDetail() {
      this.$http.get(`customer/files/${this.$route.params.id}/show`)
        .then(response => {
          if (this.$route.query && this.$route.query.tab === 'payment') {
            this.tabIndex = 1
          }
          if (this.$route.query && this.$route.query.ledger === 'receipt') {
            this.tabIndexCredit = 1
          }
          if (response.data.file.fileID) {
            this.fileDetail = response.data.file || {}
            this.invoices = response.data.invoices || []
            this.receipts = response.data.receipts || []
            document.title = `File - ${this.fileDetail.fileNo} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
            // localStorage.setItem('customerFileDetail', JSON.stringify(this.fileDetail))
            // localStorage.setItem('customerFileDetailReceipts', JSON.stringify(response.data.receipts))
            // localStorage.setItem('customerFileDetailInvoices', JSON.stringify(response.data.invoices))
            store.commit('breadcrumb/REMOVE_BREADCRUMB')
            const newMeta = {
              active: true,
              text: `${this.fileDetail.fileNo} - ${this.fileDetail.nvsFileType}`,
            }
            store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
            this.fileDetailActivityLogs = this.fileDetail.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeTab(index, tabName) {
      this.activeTab = index
      this.currentTabTitle = tabName
    },
    openEmailStatementModal() {
      this.customerNameVal = this.fileDetail.purchasers.length > 0 ? this.fileDetail.purchasers[0].name : ''
      this.customerEmailVal = this.fileDetail.purchasers.length > 0 ? this.fileDetail.purchasers[0].email : ''
      this.hasEmailAddress = this.fileDetail.purchasers.length > 0 ? this.fileDetail.purchasers[0].email : ''
      this.statementMessage = `Here is the Statement of Account for your purchase with us for ${this.fileDetail.nvsFileType} (file no.: ${this.fileDetail.fileNo}), as at ${moment().format('DD MMM YYYY')}. The statement provides a detailed summary of your transactions and current account balance.

Please be informed that the PDF is password-protected using your mobile number (e.g. 912345678).`
      this.$root.$emit('bv::show::modal', 'email-statement-modal-detail-page', '')
    },
    closeStatementModal() {
      this.$root.$emit('bv::hide::modal', 'email-statement-modal-detail-page', '')
    },
    sendEmailStatement() {
      let contact = this.customerEmailVal
      if (this.fileDetail.purchasers && this.fileDetail.purchasers.length && this.fileDetail.purchasers[0].contact) {
        contact = this.fileDetail.purchasers[0].contact.slice(-8)
      }
      const formData = new FormData()
      formData.append('customerName', this.customerNameVal)
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('message', this.statementMessage)
      formData.append('fileType', this.fileDetail.nvsFileType)
      formData.append('fileNo', this.fileDetail.fileNo)
      formData.append('contact', contact)
      formData.append('fileID', this.$route.params.id)
      formData.append('url', `${window.location.origin}`)

      this.$http.post('customer/files/send/soa/statement', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.success) {
            this.fileDetailActivityLogs = response.data.nvsFile.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'Statement of Account Email Sent',
                text: response.data.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
                hideClose: true,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resolveFileStatusVariant(status) {
      if (status === 'OPEN') return 'warning'
      if (status === 'BOOK') return 'info'
      return 'success'
    },
    getAgentImage(agents) {
      if (agents && agents.length) {
        const serviceAgent = agents.find(o => o.type === 'Sales & Service Agent' || o.type === 'Service Agent')
        if (serviceAgent && serviceAgent.image) return serviceAgent.image
      }

      return this.defaultImage
    },
    getAgentName(agents) {
      if (agents && agents.length) {
        const serviceAgent = agents.find(o => o.type === 'Sales & Service Agent' || o.type === 'Service Agent')
        if (serviceAgent && serviceAgent.name) return serviceAgent.name
      }

      return ''
    },
    resolvePaymentStatusVariant(status) {
      if (status === 'Fully Paid') return 'success'
      if (status === 'Ongoing Installment') return 'info'
      if (status === 'Pending Payment') return 'warning'
      if (status === 'Reactivation Fee') return 'danger'
      return 'secondary'
    },
    getVariant(key) {
      if (key % 4 === 0) return 'info'
      if (key % 4 === 1) return 'warning'
      if (key % 4 === 2) return 'danger'
      if (key % 4 === 3) return 'purple'
      return 'primary'
    },
    removeAttachment(name, url) {
      this.$http
        .post('customer/files/attachment/remove', { url, name, fileID: this.fileDetail._id })
        .then(response => {
          this.fileDetail.attachments = response.data.data.attachments
          this.fileDetailActivityLogs = response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setAttachment(e) {
      if (e.target.files.length) {
        // const reader = new FileReader()
        // reader.readAsDataURL(this.file)
        // reader.onload = () => {
        //   e.target.filesrc = reader.result
        // }
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        e.target.files.forEach(element => {
          if (acceptedTypes.includes(element.type)) {
            if (element.size > 5 * 1024 * 1024) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Can't add files more than 5mb",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unsupported file type',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    uploadFile() {
      const formData = new FormData()
      formData.append('attachments', JSON.stringify(this.attachments))
      formData.append('fileID', this.$route.params.id)
      formData.append('fileNo', this.fileDetail.fileNo)
      formData.append('nvsFileID', this.fileDetail._id)

      this.$http.post('customer/files/attachment/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.attachments = []
          this.fileDetail.attachments = response.data.data.attachments
          this.fileDetailActivityLogs = response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.fileDetail.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    fileRemarksEdit() {
      this.fileRemarks = this.fileDetail.remarks
      this.$root.$emit('bv::show::modal', 'modal-file-remarks', '')
    },
    saveFileRemarks() {
      this.$http
        .post('customer/files/save/file-remarks', {
          remarks: this.fileRemarks,
          oldRemarks: this.fileDetail.remarks,
          fileID: this.$route.params.id,
          nvsFileID: this.fileDetail._id,
          fileNo: this.fileDetail.fileNo,
        })
        .then(response => {
          if (response.data.success) {
            this.fileDetail.remarks = this.fileRemarks
            this.fileDetailActivityLogs = response.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
            this.closeFileRemarksModal()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          } else {
            this.closeFileRemarksModal()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeFileRemarksModal() {
      this.$root.$emit('bv::hide::modal', 'modal-file-remarks', '')
    },
    saveDescription() {
      const url = this.fileDetail.attachments[this.attachmentKey].data
      this.$http
        .post('customer/files/attachment/save-description', { url, description: this.description, fileID: this.fileDetail._id })
        .then(response => {
          this.fileDetail.attachments = response.data.data.attachments
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async intendedUserEdit(user, index) {
      this.iuIndexID = user._id
      this.iuIndex = index
      this.iuType = user.type
      this.iuID = user.entityID
      let type = ''
      const number = user.type.split('_').pop()
      if (user.type.includes('INTENDED_USER')) {
        type = 'Intended User'
      } else if (user.type.includes('NEXT OF KIN')) {
        type = 'Next Of Kin'
      } else {
        type = 'Addressee'
      }
      this.iuTypeOnly = type
      this.formattedIUType = `${type} ${number}`
      this.$refs.update_intended_user_modal.show()
      await this.$nextTick()
      this.iuName = user.name
      this.iuNRIC = user.nric
      this.iuDOB = (user.customer && user.customer.dob) ? moment(user.customer.dob).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')
      this.iuRelationship = user.relation
      this.iuContact = user.contact
      // this.iuContact = '+6512345678'
      this.iuAdd1 = (user.customer && user.customer.perAddress1) ? user.customer.perAddress1 : ''
      this.iuAdd2 = (user.customer && user.customer.perAddress2) ? user.customer.perAddress2 : ''
      this.iuAdd3 = (user.customer && user.customer.perAddress3) ? user.customer.perAddress3 : ''
      this.iuPostCode = (user.customer && user.customer.perPostcode) ? user.customer.perPostcode : ''
      this.iuCountry = (user.customer && user.customer.perCountry) ? user.customer.perCountry : ''
      this.editModalTitle = `Edit ${type} #${number}`
    },
    intendedUserRemove(user, index) {
      let type = ''
      let iyType = ''
      if (user.type.includes('INTENDED_USER')) {
        type = 'an intended user'
        iyType = 'Intended User'
      } else if (user.type.includes('NEXT OF KIN')) {
        type = 'a next of kin'
        iyType = 'Next Of Kin'
      } else {
        type = 'an addressee'
        iyType = 'Addressee'
      }
      this.$swal({
        title: `Remove ${user.name}`,
        html: `${user.name} will no longer be registered as ${type} for this file. `,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/delete.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            let parentEntity = ''
            if (this.fileDetail && this.fileDetail.purchasers && this.fileDetail.purchasers.length) {
              parentEntity = this.fileDetail.purchasers[0].entityID
            }
            formData.append('parentEntity', parentEntity)
            formData.append('fileID', this.fileDetail.fileID)
            formData.append('iuIndexID', user._id)
            formData.append('nvsFileID', this.fileDetail._id)
            formData.append('entityID', user.entityID)
            formData.append('type', user.type)

            this.$http.post(`customer/files/${this.$route.params.id}/delete/intended-user`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                if (response.data.success) {
                  if (iyType === 'Intended User') {
                    this.fileDetail.intendedUsers.splice(index, 1)
                  } else if (iyType === 'Next Of Kin') {
                    this.fileDetail.nextOfKins.splice(index, 1)
                  } else {
                    this.fileDetail.addressees.splice(index, 1)
                  }
                  this.$swal({
                    title: 'Nominee Deleted!',
                    html: `<strong>${user.name}</strong> has been removed`,
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    intendedUserSubmitForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.intendedUserEditForm.validate().then(success => {
        let validContact = false
        if (this.iuContact.length === 12) {
          this.iuContactValidation = false
          validContact = true
        } else {
          this.iuContactValidation = true
        }
        if (success && validContact) {
          this.$swal({
            title: 'Save current changes?',
            html: 'Your changes will overwrite the current information, are you sure?',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/warning.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Yes, Save Changes',
            cancelButtonText: 'No, Go Back',
            customClass: {
              confirmButton: 'btn btn-primary ml-1',
              cancelButton: 'btn btn-outline-primary',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.value) {
                const formData = new FormData()
                let parentEntity = ''
                if (this.fileDetail && this.fileDetail.purchasers && this.fileDetail.purchasers.length) {
                  parentEntity = this.fileDetail.purchasers[0].entityID
                }
                let day = '0'
                let month = '0'
                let year = '0000'
                if (this.iuDOB) {
                  day = moment(this.iuDOB, 'DD/MM/YYYY').format('D')
                  month = moment(this.iuDOB, 'DD/MM/YYYY').format('M')
                  year = moment(this.iuDOB, 'DD/MM/YYYY').format('YYYY')
                }
                formData.append('parentEntity', parentEntity)
                formData.append('fileID', this.fileDetail.fileID)
                formData.append('entityID', this.iuID)
                formData.append('type', this.iuType)
                formData.append('name', this.iuName)
                formData.append('nric', this.iuNRIC)
                formData.append('dob', moment(this.iuDOB, 'DD/MM/YYYY').format('YYYY-MM-DD'))
                formData.append('relation', this.iuRelationship)
                formData.append('phone', this.iuContact)
                formData.append('add1', this.iuAdd1)
                formData.append('add2', this.iuAdd2)
                formData.append('add3', this.iuAdd3)
                formData.append('postCode', this.iuPostCode)
                formData.append('country', this.iuCountry)
                formData.append('day', day)
                formData.append('month', month)
                formData.append('year', year)
                formData.append('iuIndexID', this.iuIndexID)
                formData.append('nvsFileID', this.fileDetail._id)

                this.$http.post(`customer/files/${this.$route.params.id}/edit/intended-user`, formData, {
                  headers: { 'Content-Type': 'multipart/form-data' },
                })
                  .then(response => {
                    if (response.data.success) {
                      // this.fileDetail.intendedUsers = response.data.fileDetail.intendedUsers
                      // this.fileDetail.nextOfKins = response.data.fileDetail.nextOfKins
                      // this.fileDetail.addressees = response.data.fileDetail.addressees
                      this.$set(this.fileDetail, 'intendedUsers', response.data.fileDetail.intendedUsers)
                      this.$set(this.fileDetail, 'nextOfKins', response.data.fileDetail.nextOfKins)
                      this.$set(this.fileDetail, 'addressees', response.data.fileDetail.addressees)
                      // if (this.iuTypeOnly === 'Intended User') {
                      //   this.$set(this.fileDetail.intendedUsers[this.iuIndex], 'relation', this.iuRelationship)
                      //   this.$set(this.fileDetail.intendedUsers[this.iuIndex], 'contact', this.iuContact)
                      //   this.$set(this.fileDetail.intendedUsers[this.iuIndex], 'Per_Addr1', this.iuAdd1)
                      //   this.$set(this.fileDetail.intendedUsers[this.iuIndex], 'Per_Addr2', this.iuAdd2)
                      //   this.$set(this.fileDetail.intendedUsers[this.iuIndex], 'Per_Addr3', this.iuAdd3)
                      //   this.$set(this.fileDetail.intendedUsers[this.iuIndex], 'Per_Postcode', this.iuPostCode)
                      //   this.$set(this.fileDetail.intendedUsers[this.iuIndex], 'Per_Country', this.iuCountry)
                      // } else if (this.iuTypeOnly === 'Next Of Kin') {
                      //   this.$set(this.fileDetail.nextOfKins[this.iuIndex], 'Relation', this.iuRelationship)
                      //   this.$set(this.fileDetail.nextOfKins[this.iuIndex], 'Per_Tel_No_Hp', this.iuContact)
                      //   this.$set(this.fileDetail.nextOfKins[this.iuIndex], 'Per_Addr1', this.iuAdd1)
                      //   this.$set(this.fileDetail.nextOfKins[this.iuIndex], 'Per_Addr2', this.iuAdd2)
                      //   this.$set(this.fileDetail.nextOfKins[this.iuIndex], 'Per_Addr3', this.iuAdd3)
                      //   this.$set(this.fileDetail.nextOfKins[this.iuIndex], 'Per_Postcode', this.iuPostCode)
                      //   this.$set(this.fileDetail.nextOfKins[this.iuIndex], 'Per_Country', this.iuCountry)
                      // }
                      this.$refs.update_intended_user_modal.hide()
                      this.$swal({
                        title: 'Nominee Updated!',
                        html: `<strong>${this.formattedIUType}</strong> has been updated`,
                        // eslint-disable-next-line global-require
                        imageUrl: require('@/assets/images/icons/save.png'),
                        imageWidth: 80,
                        imageHeight: 80,
                        imageAlt: 'Custom Icon',
                        showCancelButton: false,
                        confirmButtonText: 'Okay',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
                  .catch(error => {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  })
              }
            })
        }
      })
    },
  },
}
</script>

<style>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
  .timeline-variant-purple .timeline-item-point {
    background-color: #7367F0 !important;
  }
  .tab-parent .nav-item {
    margin-bottom: 0.75rem !important;
  }
</style>

<style lang="scss">
  @import "~@core/scss/base/pages/app-email.scss";
</style>
